/* eslint-disable @typescript-eslint/camelcase */
import { jsPDF } from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { layoutSection } from "../layout";
import importLogo from "@/app/ui/assets/images/logo-lion-parcel-black.png";
import { uploadFile } from "../auto-print";
import messageModal from "@/app/infrastructures/misc/common-library/MessageModal";

const openTab = (doc: any) => {
  doc.setProperties({
    title: `resi-thermal.pdf`
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

const generateThermalPDF = async (
  accountType: string,
  data: PrintDataEntitas[]
) => {
  const doc: any = new jsPDF("p", "mm", "a6", true);
  const logo = importLogo;
  let index = 0;
  for (const item of data) {
    if (index > 0) {
      doc.addPage("a6", "p");
    }
    const language = item.sttProductType === "INTERPACK" ? "en" : "id";
    const bookingType = () => {
      if (item.sttShipmentId) {
        return "shipment";
      } else if (!item.sttShipmentId && !item.sttClientId) {
        return "manual";
      } else {
        return "client";
      }
    };
    await layoutSection(
      {
        doc,
        bookingType: bookingType(),
        accountType,
        data: item,
        pieceData: item,
        barcode: item.barcode,
        vendorRefCode: item.vendorRefCode,
        barcodeValue: item.sttNo,
        vendorRefCodeValue: item.sttVendorRefferenceNo,
        logo,
        shipmentId: item.sttShipmentId,
        startX: 0,
        startY: 0
      },
      language
    );

    index++;
  }

  const print = await uploadFile(
    doc.output("blob"),
    `${data[0].sttNo}-bulk`,
    false
  );
  if (!print) {
    openTab(doc);
    messageModal(
      "Label Gagal Diprint",
      "circle-error",
      "Pastikan semua perangkat Anda telah terhubung. <br/> Cek & coba lagi"
    );
  } else {
    messageModal(
      "Label Berhasil Diprint",
      "badge-success",
      "Anda berhasil mencetak data yang diinginkan"
    );
  }
};

export default generateThermalPDF;
